import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

export const isDarkTheme = (theme) => theme.palette.type === 'dark';

export const theme = (prefersDarkMode) => ({
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#f59e0b',
    },
    secondary: {
      main: '#ffd895',
    },
  },
  
});
